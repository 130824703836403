import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { classes } from './EmptyList.st.css';

export interface IEmptyListProps {}

export const EmptyList: React.FC<IEmptyListProps> = (props) => {
  const { t } = useTranslation();

  return (
    <section className={classes.root}>
      <div className={classes.text}>{t('challenge.empty-list')}</div>
    </section>
  );
};
