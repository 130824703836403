import React from 'react';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import {
  ParticipantState,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';
import {
  JoinFunction,
  CancelJoinRequestFunction,
  LeaveTheChallengeFunction,
} from './userContextInterface';
import { IUserProviderProps } from './UserProvider';
import { UpdatedUserData } from './helpers/userContextHelpers';

// https://www.wix.com/corvid/reference/wix-users.html#LoginOptions
export interface IPromptLogin {
  mode?: 'login' | 'signup';
  lang?: string;
}

export enum UserState {
  VISITOR = 'VISITOR',
  MEMBER = 'MEMBER',
}

export type IUserType = UserState | ParticipantState;
export type PlatformUser = Pick<IUser, 'id' | 'instance' | 'loggedIn' | 'role'>;
export interface IUserContext {
  user: PlatformUser;
  userType: IUserType;
  participant?: V1Participant;
  promptLogin(options?: IPromptLogin): Promise<any>;
  join: JoinFunction;
  cancelJoinRequest: CancelJoinRequestFunction;
  leaveTheChallenge: LeaveTheChallengeFunction;
  incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData>;
  updateParticipant(
    participant?: V1Participant,
  ): Promise<Partial<IUserProviderProps>>;
  userTypeHandlers: {
    [index: string]: (userType: IUserType) => boolean;
  };
}

export const UserContext = React.createContext<IUserContext>({
  user: null,
  userType: null,
  promptLogin: null,
  participant: null,
  join: null,
  userTypeHandlers: null,
  async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
    return {};
  },
  async updateParticipant() {
    return {};
  },
  cancelJoinRequest: null,
  leaveTheChallenge: null,
});

export function useUser(): IUserContext {
  return React.useContext(UserContext);
}

export const UserConsumer = UserContext.Consumer;
