import React from 'react';
import { ChallengeServerlessData } from '../../api/challenges/challenge.api';

export interface IChallengeDataContext {
  isGroupsInstalled: boolean;
  challengeData?: ChallengeServerlessData;
}

export const ChallengeDataContext = React.createContext<IChallengeDataContext>({
  challengeData: null,
  isGroupsInstalled: null,
});

export function useChallengeData(): IChallengeDataContext {
  return React.useContext(ChallengeDataContext);
}

export const ChallengeDataConsumer = ChallengeDataContext.Consumer;
