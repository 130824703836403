import React from 'react';
import { BIProviderPropsMap } from './interfaces';

export interface IBIContext extends BIProviderPropsMap {}

export const BIContext = React.createContext<IBIContext>({
  beforeAndAfterAction: null,
});

export const useMemberBI = () => React.useContext(BIContext);

export const SettingsConsumer = BIContext.Consumer;
