import { ListLayoutSelectedTab } from '../Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';

export enum ButtonState {
  Default = 'default',
  Hover = 'hover',
}

export enum RolesState {
  Visitor = 'Visitor',
  Participant = 'Participant',
}

export interface ISettingsEvents {
  buttonState: ButtonState;
  pageRole: RolesState;
  listLayoutSelectedTab: ListLayoutSelectedTab;
}
