import { RolesState } from '../../Widget/settingsEvents';

export enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum ImageRatio {
  HD = 'hd',
  Standard = 'standard',
  Square = 'square',
}
export enum CropSelection {
  TopLeft = 'TOP_LEFT',
  top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

export enum ParticipantLayout {
  LIST = 'LIST',
  SIDEBAR = 'SIDEBAR',
}

export interface IParticipantPageListLayoutSettings {
  listLayoutHeaderAlignment: TextAlignment;
  listLayoutContentAlignment: TextAlignment;
  listLayoutDisplayChallengeParticipants: boolean;
  listLayoutDisplayChallengeSteps: boolean;
  listLayoutDisplayChallengeDuration: boolean;
}

export interface ISidebarLayoutSettings {
  sidebarLayoutSpace: number;
  sidebarLayoutTextAlignment: TextAlignment;
  sidebarLayoutDisplayChallengeDate: boolean;
  sidebarLayoutDisplayChallengeDuration: boolean;
  sidebarLayoutDisplayChallengeSteps: boolean;
  sidebarLayoutDisplayChallengeParticipants: boolean;
}

export type IChallengeSettings = IParticipantPageListLayoutSettings &
  ISidebarLayoutSettings & {
    editorParticipantRole: RolesState;
    headerTextAlignment: TextAlignment;
    contentTextAlignment: TextAlignment;
    imageRatio: ImageRatio;
    cropSelection: CropSelection;
    displayHeaderDate: boolean;
    displayHeaderPrice: boolean;
    displayHeaderImage: boolean;
    displayChallengeDuration: boolean;
    displayChallengeSteps: boolean;
    displayChallengeParticipants: boolean;
    displayOwner: boolean;
    displayOneApp: boolean;
    displayDivider: boolean;
    // participant page
    participantLayout: ParticipantLayout;
  };
