import { contextFactory } from '../../services/contextFactory';
import { ILoadingContext, ILoadingProps } from './interfaces';

const { ContextProvider, withConsumer } = contextFactory<
  ILoadingContext,
  ILoadingProps
>({
  defaultValues: {
    isLoading: false,
    hideLoader() {},
    showLoader() {},
  },
  displayName: 'LoadingProvider',
  propsToContext(props): ILoadingContext {
    return {
      ...props,
    };
  },
});

export const LoadingProvider = ContextProvider;

export const withLoadingAPI = withConsumer;
