import React from 'react';
import get from 'lodash/get';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';

import { Badge, BADGE_PRIORITY } from 'wix-ui-tpa/Badge';

import {
  ListChallengesResponseChallengeSummary,
  ParticipantState,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';

import { useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { Challenges } from '../../../editor/types/Experiments';

import { classes, st } from './Badge.st.css';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import settingsParams from '../settingsParams';

export interface IBadgeProps {
  summary: ListChallengesResponseChallengeSummary;
  challengeTransition?: V1ChallengeState;
  isPrivate?: boolean;
  badgeSkin?: BADGE_PRIORITY;
  badgeText?: string;
  getBadgeStrategy?(
    participantState: ParticipantState,
    isPrivate?: boolean,
  ): { tKey: string; badge?: BADGE_PRIORITY; settingsKey?: any };
  className?: string;
}

const getBadgeProps = (
  participantState: ParticipantState,
  isPrivate: boolean,
  isHappyScreensEnabled: boolean,
): { tKey?: string; badge: BADGE_PRIORITY; settingsKey?: any } => {
  if (isHappyScreensEnabled && userTypeHandlers.isCompleted(participantState)) {
    return {
      settingsKey: settingsParams.labelChallengeCompleted,
      badge: BADGE_PRIORITY.primary,
    };
  }

  if (userTypeHandlers.isJoinedAlready(participantState)) {
    return {
      settingsKey: settingsParams.labelParticipant,
      badge: BADGE_PRIORITY.primary,
    };
  }

  if (participantState === ParticipantState.JOIN_REQUESTED) {
    return {
      settingsKey: settingsParams.labelPendingRequest,
      badge: BADGE_PRIORITY.default,
    };
  }

  if (isPrivate && userTypeHandlers.isInPaymentState(participantState)) {
    return {
      settingsKey: settingsParams.labelJoinApproved,
      badge: BADGE_PRIORITY.primary,
    };
  }

  if (ParticipantState.JOIN_REJECTED === participantState) {
    return {
      settingsKey: settingsParams.labelJoinRejected,
      badge: BADGE_PRIORITY.default,
    };
  }

  return null;
};

export const ChallengeBadge: React.FC<IBadgeProps> = (props) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const settings = useSettings();
  const isHappyScreensEnabled = experiments.enabled(
    Challenges.enableHappyScreens,
  );

  const participantState = get(
    props.summary,
    'participation.state',
  ) as ParticipantState;

  const isCompletedChallenge = userTypeHandlers.isCompleted(participantState);

  if (!isHappyScreensEnabled && isCompletedChallenge) {
    return null;
  }

  const badgeStrategy = props.getBadgeStrategy
    ? props.getBadgeStrategy
    : getBadgeProps;

  const badgeProps = participantState
    ? badgeStrategy(participantState, props.isPrivate, isHappyScreensEnabled)
    : null;

  if (!badgeProps) {
    return <div data-hook="challenge-badge" />;
  }

  /*
    We have settings for background and text colors for badges from the List.
    For MA (and other places) default site colors should be used.
    So if we haven't `getBadgeStrategy` prop, it means, that this badge is from the List, and we will
      add the `list` css state for this case, and use the colors from settings.
   */

  return (
    <div
      data-hook="challenge-badge"
      className={st(
        classes.root,
        {
          list: !props.getBadgeStrategy,
          primary: badgeProps.badge === BADGE_PRIORITY.primary,
          completed: isHappyScreensEnabled && isCompletedChallenge,
        },
        props.className,
      )}
    >
      <Badge
        priority={props.badgeSkin || badgeProps.badge}
        className={classes.badge}
      >
        <span className={classes.text}>
          {props.badgeText || null}
          {!props.badgeText && badgeProps.tKey ? t(badgeProps.tKey) : null}
          {badgeProps.settingsKey ? settings.get(badgeProps.settingsKey) : null}
        </span>
      </Badge>
    </div>
  );
};
