import {
  StyleParamType,
  createStylesParams,
} from '@wix/yoshi-flow-editor/tpa-settings';
import { IChallengeStyles } from './challengeStyles.types';

export const challengeStyles = createStylesParams<IChallengeStyles>({
  mobCardBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  mobDividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  mobNameFontSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  mobParticipantsAndDurationSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobPriceSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
  mobButtonSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
  },
});
