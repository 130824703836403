import React from 'react';
import { listenToHeightChanges } from 'wix-height-updater';
import { IProvidersProps } from './intefaces';
import { withWixSDKWidget } from '../../services/withWixSDK';
import {
  IWixSDKViewerEnvironmentContext,
  useEnvironment,
  useBi,
} from '@wix/yoshi-flow-editor';
import { UserProvider } from '../User/UserProvider';
import { LocationProvider } from '../Location/LocationProvider';
import { ChallengeDataProvider } from '../ChallengeDataProvider/challengeDataProvider';
import { GeneralDataProvider } from '../GeneralDataProvider/GeneralDataProvider';
import { BIProvider } from '../BI/BIProvider';
import { ChallengesListDataProvider } from '../ChallengesListDataProvider/challengesListDataProvider';
import { InviteLinkProvider } from '../InviteLink/InviteLinkContext';
import { PaidPlansProvider } from '../PaidPlans/paidPlansContext';
import { ToastProvider } from '../ToastContext/ToastProvider';
import { ParticipantStepsDataProvider } from '../ParticipantStepsDataProvider/ParticipantStepsDataProvider';
import { ParticipantSectionsProvider } from '../ParticipantSections/ParticipantSectionsProvider';
import { ResolveStepDataProvider } from '../ResolveStep/ResolveStepDataProvider';
import { SettingsEventsProvider } from '../SettingsEvents/SettingsEvents';
import { IsStepHiddenProvider } from '../IsStepHidden/IsStepHiddenProvider';
import { LoadingProvider } from '../Loading/LoadingContext';
import { getIsFullWidth } from '../../selectors/platform';
import { LocalStorageProvider } from '../LocalStorage/LocalStorageProvider';
import { FullscreenProvider } from '../Fullscreen/FullscreenProvider';
import { VIEW_MODE } from '../../config/constants';
import { memberWebWidgetShown } from '@wix/bi-logger-challenges-member-web/v2';

export const MainProviders: React.FC<
  IProvidersProps &
    IWixSDKViewerEnvironmentContext & {
      Component: any;
    }
> = (props) => {
  const [isFullWidth, setIsFullWidth] = React.useState<boolean>(false);
  const { Component } = props;
  const { dimensions, isEditor } = useEnvironment();
  const bi = useBi();

  React.useEffect(() => {
    if (props.Wix) {
      (props.Wix as any).isFullWidth((isFW) => {
        if (isFullWidth !== isFW) {
          setIsFullWidth(isFW);
        }
      });
    }

    if (isEditor && props.Wix) {
      listenToHeightChanges(props.Wix, window);
    }

    // props.screenOpen && props.screenOpen();
    // props.reportSSRIsRendered && props.reportSSRIsRendered();

    if (props.host?.viewMode === VIEW_MODE.Site) {
      const now = Date.now();

      bi.report(
        memberWebWidgetShown({
          timeSpent: Math.max(now - props.startTimeOfRender, 0),
          itemsCount: props.challengesListData?.totalCount || 1,
        }),
      );
    }
  }, []);

  return (
    <BIProvider beforeAndAfterAction={props.beforeAndAfterAction}>
      <GeneralDataProvider
        host={props.host}
        isFullWidthLayout={props.Wix ? isFullWidth : getIsFullWidth(dimensions)}
        instanceId={props.instanceId}
        instance={props.instance}
        msid={props.msid}
        defaultBIParams={props.defaultBIParams}
        startTimeOfRender={props.startTimeOfRender}
      >
        <LocationProvider
          path={props.path}
          currentLocation={props.currentLocation}
          basePath={props.basePath}
          query={props.query}
          location={props.location}
          goToPage={props.goToPage}
          goToExternalUrl={props.goToExternalUrl}
        >
          <LocalStorageProvider
            requestItem={props.requestItem}
            setItem={props.setItem}
            storage={props.storage}
          >
            <UserProvider
              incrementParticipantsCompletedStepSummary={
                props.incrementParticipantsCompletedStepSummary
              }
              updateParticipant={props.updateParticipant}
              user={props.user}
              userType={props.userType}
              participant={props.participant}
              promptLogin={props.promptLogin}
              join={props.join}
              cancelJoinRequest={props.cancelJoinRequest}
              leaveTheChallenge={props.leaveTheChallenge}
              userTypeHandlers={props.userTypeHandlers}
            >
              <ChallengeDataProvider
                isGroupsInstalled={props.isGroupsInstalled}
                challengeData={props.challengeData}
              >
                <ChallengesListDataProvider
                  challengesListData={props.challengesListData}
                >
                  <InviteLinkProvider inviteLink={props.inviteLink}>
                    <PaidPlansProvider
                      eligiblePlans={props.eligiblePlans}
                      userPaidPlans={props.userPaidPlans}
                    >
                      <ToastProvider
                        clearError={props.clearError}
                        errorMessageKey={props.errorMessageKey}
                      >
                        <ParticipantSectionsProvider
                          participantSteps={props.participantSteps}
                          isListParticipantSectionsRequestInProgress={
                            props.isListParticipantSectionsRequestInProgress
                          }
                          listParticipantSections={
                            props.listParticipantSections
                          }
                          updateParticipantSections={
                            props.updateParticipantSections
                          }
                        >
                          <ParticipantStepsDataProvider
                            participantSteps={props.participantSteps}
                            updateParticipantSteps={
                              props.updateParticipantSteps
                            }
                            isParticipantStepsLoading={
                              props.isParticipantStepsLoading
                            }
                            updateParticipantStepStatus={
                              props.updateParticipantStepStatus
                            }
                          >
                            <ResolveStepDataProvider
                              isResolveStepRequestInProgress={
                                props.isResolveStepRequestInProgress
                              }
                              resolveStep={props.resolveStep}
                              resolveStepError={props.resolveStepError}
                            >
                              <SettingsEventsProvider
                                buttonState={props.buttonState}
                                pageRole={props.pageRole}
                                listLayoutSelectedTab={
                                  props.shownTab || props.listLayoutSelectedTab
                                }
                                shownTab={
                                  props.shownTab || props.listLayoutSelectedTab
                                }
                              >
                                <IsStepHiddenProvider
                                  isStepHidden={props.isStepHidden}
                                  isVisibleStepLockedForComplete={
                                    props.isVisibleStepLockedForComplete
                                  }
                                >
                                  <LoadingProvider
                                    showLoader={props.showLoader}
                                    hideLoader={props.hideLoader}
                                    isLoading={props.isLoading}
                                  >
                                    <FullscreenProvider
                                      toggleFullscreen={null}
                                      isFullscreen={null}
                                    >
                                      <Component {...props} />
                                    </FullscreenProvider>
                                  </LoadingProvider>
                                </IsStepHiddenProvider>
                              </SettingsEventsProvider>
                            </ResolveStepDataProvider>
                          </ParticipantStepsDataProvider>
                        </ParticipantSectionsProvider>
                      </ToastProvider>
                    </PaidPlansProvider>
                  </InviteLinkProvider>
                </ChallengesListDataProvider>
              </ChallengeDataProvider>
            </UserProvider>
          </LocalStorageProvider>
        </LocationProvider>
      </GeneralDataProvider>
    </BIProvider>
  );
};

MainProviders.displayName = 'ChallengesProviders';

export function withProviders(ProvidedComponent) {
  return withWixSDKWidget(function (props: any) {
    return <MainProviders {...props} Component={ProvidedComponent} />;
  });
}
