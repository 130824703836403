import React from 'react';

import { BIContext } from './BIContext';
import { BIProviderPropsMap } from './interfaces';

export const BIProvider: React.FC<BIProviderPropsMap> = (props) => {
  const { children } = props;
  return (
    <BIContext.Provider
      value={{
        beforeAndAfterAction: props.beforeAndAfterAction,
      }}
    >
      {children}
    </BIContext.Provider>
  );
};
