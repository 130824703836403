import React from 'react';
import {
  ParticipantStepsContext,
  IParticipantStepsContext,
} from './ParticipantStepsContext';

export const ParticipantStepsDataProvider: React.FC<IParticipantStepsContext> =
  (props) => {
    return (
      <ParticipantStepsContext.Provider
        value={{
          participantSteps: props.participantSteps,
          updateParticipantSteps: props.updateParticipantSteps,
          isParticipantStepsLoading: props.isParticipantStepsLoading,
          updateParticipantStepStatus: props.updateParticipantStepStatus,
        }}
      >
        {props.children}
      </ParticipantStepsContext.Provider>
    );
  };
