import { contextFactory } from '../../services/contextFactory';
import { IInviteLinkContext, IInviteLinkProviderProps } from './interfaces';

export const { context, ContextProvider, withConsumer } = contextFactory<
  IInviteLinkContext,
  IInviteLinkProviderProps
>({
  defaultValues: {
    inviteLink: '',
  },
  displayName: 'InviteLinkProvider',
  propsToContext(props): IInviteLinkContext {
    return {
      ...props,
    };
  },
});

export const InviteLinkContext = context;
export const InviteLinkProvider = ContextProvider;
export const withInviteLink = withConsumer;
