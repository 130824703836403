import React from 'react';
import { ILocation } from '@wix/native-components-infra/dist/src/types/types';
import { ScreenNames } from '../BI/interfaces';

export enum Pages {
  List = 'Challenges',
  Details = 'challenge_page',
  Payment = 'Challenge Payment',
  ThankYou = 'Thank You Page',
  ChallengesList = 'Challenges List Page',
}

export const PageSlugs: Record<Pages, string> = {
  [Pages.List]: 'challenges',
  [Pages.Details]: 'challenge-page',
  [Pages.Payment]: 'challenge-payment',
  [Pages.ThankYou]: 'challenge-thanks',
  [Pages.ChallengesList]: 'challenges-list',
};

export const LocalRouting: Partial<Record<Pages, string>> = {
  [Pages.Details]: '/',
  [Pages.ThankYou]: '/thanks',
  [Pages.Payment]: '/payment',
};

export const ScreenNamesByPageId: Record<Pages, ScreenNames> = {
  [Pages.Details]: ScreenNames.ChallengePageForParticipant,
  [Pages.ChallengesList]: ScreenNames.ChallengesListPage,
  [Pages.List]: ScreenNames.ChallengeListWidget,
  [Pages.Payment]: ScreenNames.PaymentPage,
  [Pages.ThankYou]: ScreenNames.ThankYouPage,
};

export interface IGoToPageOptions {
  pageId: Pages;
  challengeId?: string;
  reloadPage?: boolean;
  isAbsoluteURL?: boolean;
}

export interface ILocationContext {
  location: ILocation;
  currentLocation: string;
  basePath: string;
  path: string[];
  query: {
    [key: string]: string;
  };
  goToExternalUrl(url: string): void;
  goToPage(options: IGoToPageOptions): void;
}

export const LocationContext = React.createContext<ILocationContext>({
  location: null,
  currentLocation: '',
  path: [],
  query: {},
  basePath: '/',
  goToExternalUrl: () => {},
  goToPage: () => {},
});

export const useLocation = () => React.useContext(LocationContext);

export const LocationContextConsumer = LocationContext.Consumer;
