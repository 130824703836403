import {
  endOfWeek,
  getDay,
  startOfWeek,
  isAfter,
  addDays,
  min,
  max,
} from 'date-fns';
import { DateInterval } from '@wix/ambassador-challenge-service-web/types';

export const isCorrectStringDate = (
  date: string,
  separator = '/',
  reverse = false,
): Boolean => {
  const pattern = reverse
    ? `\\d\\d\\d\\d\\${separator}\\d\\d\\${separator}\\d\\d`
    : `\\d\\d\\${separator}\\d\\d\\${separator}\\d\\d\\d\\d`;

  return new RegExp(pattern).test(date);
};

export const getDateFromString = (
  date: string,
  separator = '/',
  reverse = false,
): Date => {
  const isCorrectDate = isCorrectStringDate(date, separator, reverse);
  let result = null;

  if (isCorrectDate) {
    const parsed = date.split(separator);
    const day = reverse ? parseInt(parsed[2], 10) : parseInt(parsed[0], 10);
    const month = parseInt(parsed[1], 10) - 1;
    const year = reverse ? parseInt(parsed[0], 10) : parseInt(parsed[2], 10);

    result = new Date(year, month, day);
  }

  return result;
};

export const getWeekRange = (
  startDate: Date,
  currentDate: Date,
  captureError?: any,
): {
  from: Date;
  to: Date;
} => {
  const dayFromStartDate = getDay(startDate);

  if (
    captureError &&
    (isNaN(dayFromStartDate) || dayFromStartDate < 0 || dayFromStartDate > 6)
  ) {
    captureError(
      `[START DATE]: startDate: ${startDate}, dayFromStartDate: ${dayFromStartDate}, currentDate: ${currentDate}`,
    );
  }

  const from = startOfWeek(currentDate, {
    weekStartsOn: dayFromStartDate,
  });

  const to = endOfWeek(currentDate, {
    weekStartsOn: dayFromStartDate,
  });

  return {
    from,
    to,
  };
};

export const sureDateAfterSpecificDate = (date: Date, dateToCompare: Date) => {
  return isAfter(date, dateToCompare) ? date : dateToCompare;
};

export const getRightDateFromBackend = (date: string): Date => {
  return getDateFromString(date, '-', true);
};

export const getRightDateFromBackendDateTime = (date: any): Date => {
  return new Date(date.year, date.month - 1, date.day);
};

export const getLastChallengeDate = (
  dateFrame: DateInterval,
  currentDate = new Date(),
) => {
  if (!dateFrame.finish) {
    return currentDate;
  }
  return addDays(new Date(getRightDateFromBackend(dateFrame.finish)), -1);
};

export const getFirstDayOfChallenge = (dateFrame: DateInterval) =>
  new Date(getRightDateFromBackend(dateFrame.start));

export const getChallengeValidDate = (
  dateFrame: DateInterval,
  currentDate: Date,
): Date => {
  const validDate = max([
    getFirstDayOfChallenge(dateFrame),
    min([getLastChallengeDate(dateFrame, currentDate), currentDate]),
  ]);
  return validDate;
};

export const getChallengeWeekRangeForDate = (
  dateFrame: DateInterval,
  date = new Date(),
  reportError?,
) => {
  return getWeekRange(
    getFirstDayOfChallenge(dateFrame),
    getChallengeValidDate(dateFrame, date),
    reportError,
  );
};
