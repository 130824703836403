import get from 'lodash/get';
import {
  MemberChallenge,
  ParticipantState,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';
import userTypeHandlers from '../../../contexts/User/helpers/userTypeHandlers';
import settingsParams from '../settingsParams';

export const filterChallenges = (
  challenges: MemberChallenge[] = [],
  settings: any,
): MemberChallenge[] => {
  return challenges.filter((challenge) => {
    const participantState = get(
      challenge.summary,
      'participation.state',
    ) as ParticipantState;
    const isFinished =
      get(challenge, 'challenge.transitions[0].state') ===
      V1ChallengeState.FINISHED;
    const isParticipant = userTypeHandlers.isJoinedAlready(participantState);

    const currChallengesFromSettings =
      settings.get(settingsParams.challengesVisible)[challenge.challenge.id] ||
      settings.get(settingsParams.challengesVisible)[
        challenge.challenge.settings.description.title
      ];

    const isChallengeVisible =
      !settings.get(settingsParams.challengesVisible) ||
      typeof currChallengesFromSettings === 'undefined'
        ? true
        : !currChallengesFromSettings?.hidden;

    return (!isFinished || isParticipant) && isChallengeVisible;
  });
};

export const orderChallenges = (
  challenges: MemberChallenge[] = [],
  settings: any,
): MemberChallenge[] => {
  const orderFromSettings =
    settings.get(settingsParams.challengesOrdering)?.order || [];

  return orderFromSettings.length
    ? [
        ...orderFromSettings
          .map((orderedChall: { id: string }) => {
            const ch = challenges.find((_ch) => {
              return _ch?.challenge?.id === orderedChall?.id;
            });

            return ch || null;
          })
          .filter((item) => !!item),
        ...challenges.filter((ch) => {
          const isInOrder = orderFromSettings.find(
            (i: { id: string }) => i.id === ch?.challenge?.id,
          );
          return !isInOrder;
        }),
      ]
    : challenges;
};
