import React from 'react';

import { V1ParticipantStep } from '@wix/ambassador-challenge-service-web/types';

export interface IIsStepHiddenContext {
  isStepHidden(step: V1ParticipantStep): boolean;
  isVisibleStepLockedForComplete(step: V1ParticipantStep): boolean;
}

export const IsStepHiddenContext = React.createContext<IIsStepHiddenContext>({
  isStepHidden: null,
  isVisibleStepLockedForComplete: null,
});

export const IsStepHiddenConsumer = IsStepHiddenContext.Consumer;

export const useIsStepHidden = () => React.useContext(IsStepHiddenContext);

export function withIsStepHidden<T>(Component: any): React.FC<T> {
  return (props) => {
    return (
      <IsStepHiddenConsumer>
        {(value) => {
          return <Component {...(props as T)} {...value} />;
        }}
      </IsStepHiddenConsumer>
    );
  };
}
