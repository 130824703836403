import React from 'react';
import { ChallengeDataContext } from './ChallengeDataContext';
import { IChallengeDataProps } from './helpers';

export const ChallengeDataProvider: React.FC<IChallengeDataProps> = (props) => {
  return (
    <ChallengeDataContext.Provider
      value={{
        challengeData: props.challengeData,
        isGroupsInstalled: props.isGroupsInstalled,
      }}
    >
      {props.children}
    </ChallengeDataContext.Provider>
  );
};
