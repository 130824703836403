import React from 'react';
import {
  ListParticipantStepsResponse,
  ParticipantSection,
} from '@wix/ambassador-challenge-service-web/types';

export interface IParticipantSectionsContext {
  isListParticipantSectionsRequestInProgress: boolean;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  updateParticipantSections(): void;
}

export const ParticipantSectionsContext =
  React.createContext<IParticipantSectionsContext>({
    isListParticipantSectionsRequestInProgress: false,
    listParticipantSections: null,
    participantSteps: null,
    updateParticipantSections: null,
  });

export const useSections = () => React.useContext(ParticipantSectionsContext);

export const ParticipantSectionsConsumer = ParticipantSectionsContext.Consumer;
