import { createSettingsParams } from '@wix/yoshi-flow-editor/tpa-settings';

import {
  IChallengeSettings,
  TextAlignment,
  ImageRatio,
  CropSelection,
  ParticipantLayout,
} from './Settings/challengeSettings/challengeSettings.types';
import { RolesState } from './Widget/settingsEvents';

export default createSettingsParams<IChallengeSettings>({
  /**
   * sidebar layout
   */
  sidebarLayoutTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  sidebarLayoutSpace: {
    inheritDesktop: false,
    getDefaultValue() {
      return 0;
    },
  },
  /**
   * participant page list layout
   */
  listLayoutHeaderAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  listLayoutContentAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  listLayoutDisplayChallengeParticipants: {
    getDefaultValue() {
      return true;
    },
  },
  listLayoutDisplayChallengeSteps: {
    getDefaultValue() {
      return true;
    },
  },
  listLayoutDisplayChallengeDuration: {
    getDefaultValue() {
      return true;
    },
  },
  /**
   * participant page sidebar layout
   */
  sidebarLayoutDisplayChallengeDate: {
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeDuration: {
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeSteps: {
    getDefaultValue() {
      return true;
    },
  },
  sidebarLayoutDisplayChallengeParticipants: {
    getDefaultValue() {
      return true;
    },
  },
  /**
   * visitor settings
   */
  editorParticipantRole: {
    inheritDesktop: false,
    getDefaultValue() {
      return RolesState.Visitor;
    },
  },
  headerTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  contentTextAlignment: {
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  imageRatio: {
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  displayHeaderDate: {
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderPrice: {
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderImage: {
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeDuration: {
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeSteps: {
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeParticipants: {
    getDefaultValue() {
      return true;
    },
  },
  displayOwner: {
    getDefaultValue() {
      return true;
    },
  },
  displayOneApp: {
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    getDefaultValue() {
      return true;
    },
  },
  participantLayout: {
    getDefaultValue() {
      return ParticipantLayout.LIST;
    },
  },
});
